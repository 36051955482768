import React from "react"
import BlogPreviewTile from "./BlogPreviewTile"

export default () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <BlogPreviewTile />
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <BlogPreviewTile />
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <BlogPreviewTile />
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <BlogPreviewTile />
          </div>
        </div>
      </div>
    </section>
  )
}
